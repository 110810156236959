@charset "utf-8";

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/NotoSansKR-Light-Hestia.woff') format('woff'),
       url('../fonts/NotoSansKR-Light-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/NotoSansKR-Regular-Hestia.woff') format('woff'),
       url('../fonts/NotoSansKR-Regular-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/NotoSansKR-Medium-Hestia.woff') format('woff'),
       url('../fonts/NotoSansKR-Medium-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/NotoSansKR-Bold-Hestia.woff') format('woff'),
       url('../fonts/NotoSansKR-Bold-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}
